import axios from "axios";
import Apiconfigs from "../config";
import { toast } from "react-toastify";
export const dataPostHandler = async (endPoint, dataToSend) => {
  try {
    const res = await axios({
      method: "POST",
      url: Apiconfigs[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: dataToSend,
    });
    if (res.data.responseCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    if (error.response.status === 440) {
      return error;
    } else if (error?.response?.data?.responseCode === 409) {
      //toast.error(error?.response?.data?.responseMessage);
      return error;
    }
    else if (error?.response?.data?.responseCode === 402) {
      //toast.error(error?.response?.data?.responseMessage);
      return error;
    } else if (error?.response?.data?.responseCode === 404) {
      console.log("error", error);
      //toast.error(error?.response?.data?.responseMessage);
      return error;
    } else {
      return error;
    }
    return error;
  }
};

export const getDataHandlerAPI = async ({ endPoint, token, paramsData }) => {
  try {
    const res = await axios({
      method: "GET",
      url: Apiconfigs[endPoint],
      headers: {
        token: token,
      },
      params: paramsData
    });
    if (res.data.responseCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    return false;
  }
};

export const patchAPIHandler = async (endPoint, dataToSend) => {
  try {
    const res = await axios({
      method: "PATCH",
      url: Apiconfigs[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: dataToSend,
    });
    if (res.data.responseCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    } else if (error.response.status === 400) {
      //toast.error(error?.response?.data?.responseMessage);
    } else {
      //toast.error(error?.response?.data?.responseMessage);
      return false;
    }
  }
};

export const putAPIHandler = async (endPoint, dataToSend) => {
  try {
    const res = await axios({
      method: "PUT",
      url: Apiconfigs[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: dataToSend,
    });
    if (res.data.responseCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    //toast.error(error?.response?.data?.responseMessage);
    return false;
  }
};

export const getParticularObjectDataHanndler = async (endPoint, _id) => {
  try {
    const res = await axios({
      method: "GET",
      url: `${Apiconfigs[endPoint]}`,
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      params: _id,
    });
    if (res) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    return error?.response?.data?.responseMessage;
  }
};

export const deleteDataAPIHandler = async (endPoint, _id) => {
  try {
    const res = await axios({
      method: "DELETE",
      url: Apiconfigs[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      params: {
        _id: _id,
      },
    });
    if (res.data.responseCode === 200) {
      return res.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    return false;
  }
};

export const postAPIHandlerIdInPath = async (endPoint, _id) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${Apiconfigs[endPoint]}/${_id}`,
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
    });
    if (res.data.responseCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    //toast.error(error?.response?.data?.responseMessage);
    return false;
  }
};
