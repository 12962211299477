import React, { useState, useEffect } from "react";
import {
  Box,
  List,
  makeStyles,
  Avatar,
  Divider,
  Typography,
  Button,
  Grid,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  FormControl,
  MenuItem,
  Select,
} from "@material-ui/core";
import { MdCancel } from "react-icons/md";
import Apiconfigs from "src/apiConfig/config";
import Axios from "axios";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import { useHistory } from "react-router-dom";
import { handleMultipleFileUpload } from "src/utils";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { toast } from "react-toastify";
import { localeFunction } from "src/utils";
import CropImage from "src/component/Crop/CropImage";
import base64js from 'base64-js';
const useStyles = makeStyles((theme) => ({
  AddPetPype: {
    background: "#6fcfb9",
    color: "#fff",
    "&:hover": {
      background: "#6fcfb9",
      color: "#fff",
    },
  },
  imageSectionPet:{
    width: "100%",
    borderRadius: "50%",
    height: "100%",
  },
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    color: "#6fcfb9",
  },
  MuiContainerClass: {
    width: "500px",
    height: "500px",
    "@media(max-width:720px": {
      width: "250px",
      height: "250px",
    },
  },
  profileBox: {},
}));
export const getBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (err) { };
};
const ProfileModal = ({
  petCategoryList,
  handleCloseShare,
  handleOpenShare,
  openEdit,
  _selectpetType,
  setSelectPetType,
  _petcategoryliststate,
  _selectpetType1,
  setSelectPetType1,
  profileImage64,
  setProfileImage,
  setProfileImage64,
  PetCategoryType,
  PetTypeProfile,
  profileData,
  // setOpen,
  _socialData,
  type,
}) => {
  const classes = useStyles();
  const [_isloading, setIsLoading] = useState(false);
  const [_isloadingprofile, setIsLoadingprofile] = useState(false);
  const [_uploadprofile, setUploadProfile] = useState("");
  const [_pettypename, setPetTypename] = useState("");
  const [_selectpetType2, setSelectPetType2] = useState("");
  const [petBreedList, setPetBreedList] = useState([]);
  const [mediaType, setMediaType] = useState();
  const [psotImage64, setPsotImage64] = useState();
  const [imageBlob, setImageBlob] = useState("");
  const history = useHistory();
  useEffect(() => {
    if (profileData !== "") {
      setSelectPetType(profileData?.petCategoryId?._id);
      setSelectPetType1(profileData?.petCategoryId?.petCategoryType);
      setProfileImage64(profileData?.userProfileImage);
    }
  }, [profileData]);
  const PetBreedType = async () => {
    try {
      const res = await Axios({
        method: "POST",
        url: Apiconfigs.petBreedList,
        data: { petCategoryId: profileData?.petBreedId }
      });
      if (res.data.responseCode === 200) {
        setPetBreedList(res?.data?.result);
      }
    } catch (error) { }
  };

  useEffect(() => {
    PetBreedType();
  }, [])

  // console.log('skfljsdfkljsdfjkl', profileImage64)
  const AddPetProfile = async () => {
    if (
      _selectpetType1 !== "" &&
      profileImage64 !== "" &&
      _selectpetType !== 0
    ) {
      try {
        setIsLoading(true);
        const res = await Axios({
          url: Apiconfigs?.addUserProfile,
          method: "POST",
          headers: {
            token: window.sessionStorage.getItem("token") || _socialData?.token,
          },
          data: {
            petCategoryType: _selectpetType1,
            name: _pettypename,
            userProfileImage: profileImage64,
            petCategoryId: _selectpetType,
            petBreedId: _selectpetType2
          },
        });
        if (res) {
          if (type === "LOGIN") {
            setIsLoading(false);
            handleCloseShare();
            PetTypeProfile();
          } else {
            history.push({
              pathname: "/suggestion",
              state: {
                tokenValue: _socialData?.token,
              },
            });
          }
        }
      } catch (error) {
        if (error.response?.data?.responseCode === 409) {
          //toast.error(error.response?.data?.responseMessage);
          setIsLoading(false);
        }

        setIsLoading(false);
      }
    } else {
      if (_uploadprofile === "") {
        //toast.error("Please upload pet profile image.");
      } else if (_selectpetType1 === "") {
        //toast.error("Please select pet type.");
      } else {
        //toast.error("Please select pet category.");
      }
    }
  };

  const UpdatePetProfile = async () => {
    if (
      _selectpetType1 !== "" &&
      _uploadprofile !== "" &&
      _selectpetType !== 0
    ) {
      try {
        setIsLoading(true);
        const res = await Axios({
          url: Apiconfigs?.userProfileUpdate,
          method: "POST",
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
          data: {
            petCategoryType: profileData?.userId?.petType,
            name: _pettypename,
            userProfileImage: _uploadprofile,
            petCategoryId: _selectpetType,
          },
          params: {
            _id: profileData?._id,
          },
        });
        if (res) {
          setIsLoading(false);

          handleCloseShare();
          PetTypeProfile();
        }
      } catch (error) {
        if (error.response?.data?.responseCode === 409) {
          //toast.error(error.response?.data?.responseMessage);
          setIsLoading(false);
        }

        setIsLoading(false);
      }
    } else {
      if (_uploadprofile === "") {
        //toast.error("Please upload pet profile image.");
      } else if (_selectpetType1 === "") {
        //toast.error("Please select pet type.");
      } else {
        //toast.error("Please select pet category.");
      }
    }
  };

  const handlefilechange1 = async (e) => {
    setIsLoadingprofile(true);
    let res = await handleMultipleFileUpload(e);
    if (res?.data?.responseCode === 200) {
      setUploadProfile(res.data.result[0]?.mediaUrlWeb);
      setIsLoadingprofile(false);
    } else {
      setIsLoadingprofile(false);
    }
  };

  const onImageChangeHandler = (e) => {
    const image = e.target.files[0];
    setMediaType(image.type.split("/")[0]);
    getBase64(e.target.files[0], (result) => {
      // setImage(result);
      setProfileImage64(result);
    });
  };

  function base64ToFile(base64String) {
    const mimeType = getMimeTypeFromBase64(base64String); // Get the MIME type from the Base64 string
    // const ext = originalFilename.split('.').pop(); // Extract the file extension
    const uniqueFilename = generateOriginalFilename(mimeType); // Generate a unique filename
    const paddedBase64String = padBase64String(base64String); // Add padding to the Base64 string
    
    const decodedData = base64js.toByteArray("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAA6wAAASnCAYAAADhW/UJAAAAAXNSR0IArs4c6QAAIABJREFUeF7EvemPZVuS5WVnvqPP7jG89/INVd1Qgub/Q+IDohC0oD8gKJpWQ5f4o6BaVZmV+aaYfHa/8xnRb9ne1z2GTLKAFq70jHgefu89Z5+9zWyZLVuW/I//9X8+JElifPVDb9umtrZtzSyxoshtGMz6vrMsy4zfS5LUsiz1v6eZ5dXILEn5JRv63vqut"); // Decode the Base64 string
    const dataLength = decodedData.length;
    const buffer = new Uint8Array(dataLength); // Create a Uint8Array to hold the binary data

    for (let i = 0; i < dataLength; i++) {
      buffer[i] = decodedData.charCodeAt(i); // Populate the Uint8Array with the binary data
    }

    // Create a Blob object from the Uint8Array
    const blob = new Blob([buffer.buffer], { type: mimeType });

    // Create the File object with the provided data, unique filename, and MIME type
    return new File([blob], uniqueFilename, { type: mimeType });
  }

  function getMimeTypeFromBase64(base64String) {
    const matches = base64String.match(/^data:(.*);base64,/);
    return matches ? matches[1] : null; // Return MIME type if found, otherwise null
  }

  function padBase64String(base64String) {
    const paddingLength = 4 - (base64String.length % 4);
    return base64String + "=".repeat(paddingLength);
  }

  function generateOriginalFilename(mimeType) {
    const timestamp = Date.now();
    const ext = mimeType.split('/')[1];
    return `file_${timestamp}.${ext}`;
  }

  return (
    <div>
      <Box>
        <Dialog open={openEdit} onClose={handleCloseShare}>
          <DialogContent className={classes.MuiContainerClass} style={{}}>
            {imageBlob && (
              <CropImage
                selectedFile={imageBlob && imageBlob}
                setCropData={(data) => setProfileImage64(data)}
              // aspectRatio={16 / 9}
              />
            )}
            <IconButton
              className={classes.cancelBtn}
              onClick={handleCloseShare}
            >
              <MdCancel />
            </IconButton>
            <Box display={"flex"} justifyContent="center" mb={2}>
              <Typography variant="h4">
                {profileData?.status !== "ACTIVE"
                  ? `${localeFunction("Add_pet_profile_text_1")}`
                  : `${localeFunction("Add_pet_profile_text_2")}`}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <label htmlFor="raised-button-file" className="selectImageBtnPet">
                <Box
                  className={classes.profileBox}
                  style={{
                    width: "90px",
                    height: "90px",
                    border: "2px solid #6fcfb9",
                    borderRadius: "100px",
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    // className="imageSectionPet"
                   style={{width: "100%",
                   borderRadius: "50%",
                   height: "100%",}}
                    src={
                      profileImage64
                        ? profileImage64
                        : "/images/dog-placeholder.png"
                    }
                  />
                  <input
                    style={{ display: "none" }}
                    id="raised-button-file"
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      // handlefilechange1(e.target.files[0]);
                      // getBase64(e.target.files[0], (result) => {
                      //   setProfileImage64(result);
                      // });
                      setImageBlob(e.target.files[0]);
                      onImageChangeHandler(e);
                    }}
                  />
                  <>
                    <Box display="flex" justifyContent="center">
                      {_isloadingprofile ? (
                        <></>
                      ) : (
                        <Box className={classes.upload}>
                          <Box className="iconimg">
                            <Avatar
                              style={{
                                cursor: "pointer",
                                width: "17px",
                                height: "17px",
                                background: "aliceblue",
                                position: "absolute",
                                right: "2px",
                                bottom: "5px",
                              }}
                            >
                              <AddCircleIcon
                                className="iconClassPlus"
                              // style={{
                              //   color: "#fff !important",
                              // }}
                              />
                            </Avatar>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </>
                </Box>
              </label>
            </Box>
            <Box display={"flex"} justifyContent="center">
              {_isloadingprofile && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    paddingRight: "12px",
                    width: "40px",
                    height: "40px",
                    background: "#6fcfb9",
                    borderRadius: "50%",
                  }}
                >
                  <ButtonCircularProgress />
                </Box>
              )}
            </Box>
            <Box mb={1} mt={2}>
              <Typography variant="body2">
                {localeFunction("signUp_text_18")}
              </Typography>
            </Box>
            <Select
              variant="outlined"
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              fullWidth
              disabled={profileData?.status === "ACTIVE" || _isloading}
              style={{ padding: "4px" }}
              className="countryField"
              margin="dense"
              name="petType"
              value={_selectpetType}
              onChange={(e) => setSelectPetType(e.target.value)}
              renderValue={(selected) => {
                let petName = petCategoryList.find(
                  (val) => val._id === selected
                )?.petCategoryName;
                setPetTypename(petName);
                return petName;
              }}
            >
              <MenuItem value={0}>{localeFunction("signUp_text_19")}</MenuItem>

              {petCategoryList &&
                petCategoryList.map((data) => {
                  return (
                    <MenuItem key={data?._id} value={data?._id}>
                      {data?.petCategoryName}
                    </MenuItem>
                  );
                })}
            </Select>
            <Box mt={2}>
              <Typography variant="h6">Pet Category</Typography>
              <FormControl
                inputVariant="outlined"
                className={classes.formControl}
                fullWidth
              >
                {profileData?.status === "ACTIVE" ? (
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    name="breed"
                    placeholder={localeFunction("Add_pet_profile_text_3")}
                    type="text"
                    value={profileData?.userId?.petType}
                    // error={Boolean(touched.breed && errors.breed)}
                    disabled
                    inputProps={{ maxLength: 91 }}
                  />
                ) : (
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={_selectpetType1}
                    onChange={(e) => setSelectPetType1(e.target.value)}
                    label="Age"
                    disabled={profileData?.status === "ACTIVE" || _isloading}
                  >
                    <MenuItem value={0}>
                      {localeFunction("signUp_text_19")}
                    </MenuItem>

                    {_petcategoryliststate &&
                      _petcategoryliststate.map((data) => {
                        return (
                          <MenuItem key={data} value={data}>
                            {data}
                          </MenuItem>
                        );
                      })}
                  </Select>
                )}
              </FormControl>
            </Box>
            <Box mt={2}>
              <Typography variant="h6">{localeFunction("Select_breed")}</Typography>
              <FormControl
                inputVariant="outlined"
                className={classes.formControl}
                fullWidth
              >
                {profileData?.status === "ACTIVE" ? (
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    name="breed"
                    placeholder={localeFunction("Select_breed")}
                    type="text"
                    value={profileData?.userId?.petBreedId}
                    // error={Boolean(touched.breed && errors.breed)}
                    disabled
                    inputProps={{ maxLength: 91 }}
                  />
                ) : (
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={_selectpetType2}
                    onChange={(e) => setSelectPetType2(e.target.value)}
                    label="Age"
                    disabled={profileData?.status === "ACTIVE" || _isloading}
                  >
                    <MenuItem value={0}>
                      {localeFunction("Select_breed")}
                    </MenuItem>

                    {petBreedList &&
                      petBreedList.map((data) => {
                        return (
                          <MenuItem key={data?._id} value={data?._id}>
                            {data?.petBreedName}
                          </MenuItem>
                        );
                      })}
                  </Select>
                )}
              </FormControl>
            </Box>
            <Box display={"flex"} mb={3} mt={2}>
              <Button
                className={classes.AddPetPype}
                color="primary"
                variant="container"
                onClick={handleCloseShare}
                disabled={_isloading || _isloadingprofile}
              >
                {localeFunction("Add_Missing_Pets_81")}
              </Button>{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {profileData?.status !== "ACTIVE" ? (
                <Button
                  className={classes.AddPetPype}
                  color="primary"
                  variant="container"
                  onClick={AddPetProfile}
                  disabled={_isloading || _isloadingprofile}
                >
                  {localeFunction("Add_pet_profile_text_4")}
                  {_isloading && <ButtonCircularProgress color="#fff" />}
                </Button>
              ) : (
                <Button
                  className={classes.AddPetPype}
                  color="primary"
                  variant="container"
                  onClick={UpdatePetProfile}
                  disabled={_isloading || _isloadingprofile}
                >
                  {localeFunction("prodile_own_text_2")}
                  {_isloading && <ButtonCircularProgress color="#fff" />}
                </Button>
              )}
              {/* <Button
                className={classes.AddPetPype}
                color="primary"
                variant="container"
                onClick={UpdatePetProfile}
                disabled={_isloading || _isloadingprofile}
              >
                Add Profile{" "}
                {_isloading && <ButtonCircularProgress color="#fff" />}
              </Button> */}
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </div>
  );
};

export default ProfileModal;
