import React, { useState, useEffect, useContext, useRef } from "react";
import {
  makeStyles,
  Dialog,
  DialogContent,
  IconButton,
  FormControl,
  Typography,
  Box,
  Button,
  Grid,
  Avatar,
} from "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { IoClose } from "react-icons/io5";
import { HiUserAdd } from "react-icons/hi";
import { MdAddLocationAlt } from "react-icons/md";
import { dataPostHandler } from "src/apiConfig/service";
import Apiconfigs from "src/apiConfig/config";
import ButtonCircularProgress from "./ButtonCircularProgress";
import { toast } from "react-toastify";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { IoMdArrowDropdown } from "react-icons/io";
import Axios from "axios";
import ModalEmoji from "src/component/ModalEmoji";
import { AuthContext } from "src/context/Auth";
import DeleteIcon from "@material-ui/icons/Delete";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import FbImageGrid from "react-fb-image-grid";
import { localeFunction } from "src/utils";

const useStyles = makeStyles((theme) => ({
  mainmodalBox: {
    "& .formControl": {
      width: "100%",
      backgroundColor: "transparent",
      border: "none",
      color: "#fff",
      "&:focus-visible": {
        outline: "none",
      },
    },
  },
  cancelBtn: {
    color: "#6fcfb9",
    "& svg": {
      color: "#6fcfb9",
      fontWeight: "700",
    },
  },

  imageBox: {
    width: "50px",
    height: "50px ",
    paddingRight: " 8px",
  },
  commentControl: {
    "& .MuiInputBase-root": {
      boxShadow: "none !important",
      height: "90px !important",
      border: "none",
      "&:hover": {
        borderColor: "white",
        boxShadow: "none !important",
      },
    },

    "& .allIconInput": {
      "@media(max-width:408px)": {
        padding: "4px",
        "& svg": {
          fontSize: "17px",
        },
      },
    },
  },

  emojiParent: {
    position: "relative",
    alignItems: "center",
    "& h4": {
      fontSize: "16px",
      fontWeight: "600",
    },
  },

  newLocationAddress: {
    display: "flex",
    justifyContent: "space-between",
    "& .flexbox": {
      display: "flex",
      alignItems: "center",
    },
  },
  borderClass: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "0.5px solid #E1E1E1",
    padding: "2px 0 14px",
    "& h6": {
      fontSize: "20px",
      fontWeight: "600",
      width: "100%",
      textAlign: "center",
    },
  },
  boxUser: {
    background: "#e4e6eb",
    borderRadius: "5px",
    height: "25px",
  },
  PostBox: {
    marginTop: "20px",
    border: "1px solid",
    padding: "10px",
    borderRadius: "6px",
    height: "55px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& h4": {
      color: "#000",
      fontSize: "15px",
      fontWeight: "500",
      [theme.breakpoints.down("xs")]: {
        fontSize: "13px",
      },
    },
  },

  dropzoneBox: {
    padding: "5px",
    border: "1px solid #bfbfbf !important",
    borderRadius: "10px",
    position: "relative",
    minHeight: "25vh",
  },
  TextFiles: {
    border: "none",
    fontSize: "14px",
    fontWeight: "800",
    marginTop: "10px",
  },

  lableText: {
    margin: " 0 auto",
    display: " flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    cursor: "pointer",
    fontSize: "large",

    position: "absolute",
    zIndex: 1,
    background: "#fff",
    left: "34%",
    top: "30px",
    padding: "5px 10px",
    "@media(max-width:600px)": {
      left: "10%",
    },
  },

  inputFile: {
    display: "none !important",
  },
  images: {
    display: " flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: "-20px",
  },
  image: {
    margin: "1rem 0.5rem",
    position: "relative",
    boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
  },
  deleteBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    cursor: "pointer",
    border: "none",
    color: "white",
    minWidth: "30px !important",
    backgroundColor: "#6fcfb9",
    borderRadius: "50px",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#6fcfb9",
    },
  },
  addPhotoIcon: {
    width: "35px",
    height: "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    background: "#e4e6eb",
  },
  photoCancelBtn: {
    background: "#e4e6eb",
    right: 0,
    position: "absolute",
    zIndex: 11,
  },
  iconBtns: {
    color: "#6FCFB9",
    fontSize: "30px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
    },
  },
}));

function CreatePostModal({
  openCreatePost,
  handleCloseCreatePost,
  handleClickFriendModal,
  handleClickOpenModal,
  handleformSubmit,
  handlecloseformSubmit,
}) {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [allImages, setAllImages] = useState([]);
  const [inputStr, setInputStr] = useState("");
  const [successFile, setSuccessFile] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [_hidedragdrop, setHideDrop] = useState(false);
  const [allUploaded, setAllUploaded] = useState([]);
  const userTagData = window.localStorage.getItem("userTagData");
  const finaltaguserdata = JSON.parse(userTagData);
  const [_tagfriend, setTagFriend] = useState([]);
  const [isUploading1, setIsUploading1] = useState(false);
  const [_showDropzone, setShowDropZone] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImages2, setSelectedImages2] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [selectedAll, setSelectedAll] = useState([]);
  const [addFileOpen, setAddFileOpen] = useState(true);
  const [userTagList, setUserTagList] = useState([]);
  useEffect(() => {
    if (userTagData) {
      setTagFriend(userTagData);
    }
  }, [userTagData]);
  const onSelectFile = (event) => {
    if (selectedAll.length < 5) {
      const selectedFiles = event.target.files[0];
      if (selectedFiles.size < 25000000) {
        const selectedFileType = event.target.files[0].type.split("/")[1];
        const blobObj = URL.createObjectURL(event.target.files[0]);
        setSelectedAll((selectedAll) => [...selectedAll, selectedFiles]);
        if (
          selectedFileType === "png" ||
          selectedFileType === "jpeg" ||
          selectedFileType === "gif" ||
          selectedFileType === "gif" ||
          selectedFileType === "webp"
        ) {
          setSelectedImages((selectedImages) => [...selectedImages, blobObj]);
          setSelectedImages2((selectedImages2) => [
            ...selectedImages2,
            {
              blobData: blobObj,
              fileName: selectedFiles.name,
            },
          ]);
        } else {
          setSelectedVideos((selectedVideos) => [
            ...selectedVideos,
            {
              blobData: blobObj,
              fileName: selectedFiles.name,
            },
          ]);
        }
      } else {
        //toast.error(localeFunction("fedd_story_validation_text"));
      }
    } else {
      //toast.error(localeFunction("fedd_story_validation_text1"));
    }
  };
  function deleteHandlerImage(image) {
    setSelectedAll(
      selectedAll.filter(
        (e) =>
          e.name !==
          selectedImages2.find((val) => val.blobData === image).fileName
      )
    );
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }
  function deleteHandlerVideo(image, filename) {
    setSelectedAll(selectedAll.filter((e) => e.name !== filename));
    setSelectedVideos(selectedVideos.filter((e) => e.blobData !== image));
    URL.revokeObjectURL(image);
  }
  function DeleteAllFiles() {
    setAddFileOpen(false);
    setSelectedImages([]);
    setSelectedAll([]);
    setSelectedVideos([]);
  }
  function toggleFileBtn(){
    setAddFileOpen(!addFileOpen);

  }
  const handleFormSubmit = async () => {
    const ans_array = window.localStorage.getItem("tagfrndiddata");
    var data = new FormData();
    Object.values(selectedAll).forEach((res) => {
      data.append("files", res);
    });
    data.append("title", inputStr);
    data.append("metaWords", '["test","test"]');
    data.append("tagPeople", ans_array);
    data.append("lat", window.localStorage.getItem("UserLatitude"));
    data.append("long", window.localStorage.getItem("UserLongitute"));
    data.append(
      "address",
      window.localStorage.getItem("postLocation") !== null
        ? window.localStorage.getItem("postLocation")
        : ""
    );
    try {
      setIsLoading(true);
      const res = await Axios({
        method: "POST",
        url: Apiconfigs.addPost,
        headers: {
          token: sessionStorage.getItem("token"),
        },
        data: data,
      });
      if (res?.data !== undefined && res?.data?.responseCode !== undefined) {
        if (res.data.responseCode === 200) {
          setInputStr("");
          toast.success(res.data.responseMessage);
          handleCloseCreatePost();
          auth.homePagePostListHandler(window.sessionStorage.getItem("token"));
          // window.localStorage.removeItem("UserLatitude");
          handleformSubmit();
          window.localStorage.removeItem("tagfrndiddata");
          window.localStorage.removeItem("postLocation");
          window.localStorage.removeItem("tagfnIdd");
          window.localStorage.removeItem("userTagData");
          responsehandletagfrnd();
          setIsLoading(false);
          setAllImages([]);
          setSelectedImages([]);
          setSelectedAll([]);
          setSelectedVideos([]);
        } else if (res) {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const responsehandletagfrnd = async (values) => {
    const response = await dataPostHandler("tagPeopleList", {});
  };
  const handleRemove = (value) => {
    const filterFun = allImages.filter((image) => {
      return image["data_url"] !== value;
    });
    if (filterFun) {
      setAllImages(filterFun);
    } else {
      setAllImages([]);
    }
  };
  const onEmojiClick = (event, emojiObject) => {
    setInputStr((data) => data + event.emoji);
  };
  useEffect(() => {
    if (openCreatePost) {
      handlecloseformSubmit();
      window.localStorage.removeItem("tagfnIdd");
    }
  }, [openCreatePost]);
  useEffect(() => {
    if (window.localStorage.getItem) {
      setUserTagList(window.localStorage.getItem("userTagData"));
    }
  }, [window.localStorage.getItem("userTagData")]);
  useEffect(() => {
    window.localStorage.removeItem("postLocation");
  }, []);

  return (
    <Dialog
      open={openCreatePost}
      onClose={() => {
        if (!isLoading) {
          handleCloseCreatePost();
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
      fullWidth
    >
      <Box align="center">
        <Box className={classes.borderClass} mb={2}>
          <Typography variant="h6">
            {localeFunction("feed_create_post_text")}
          </Typography>
          <IconButton
            className={classes.cancelBtn}
            onClick={() => {
              handleCloseCreatePost();
              // handleremoveLocation();
            }}
          >
            <IoClose />
          </IconButton>
        </Box>
      </Box>
      <DialogContent>
        <Box display="flex" pt={1} className={classes.emojiParent}>
          <Box className={classes.imageBox}>
            <Box>
              <Avatar src={auth?.userData?.profilePic} />
            </Box>
            &nbsp;&nbsp;
          </Box>{" "}
          <Box>
            <Typography variant="h4">{auth?.userData?.name}</Typography>
            <Box display={"flex"} className={classes.boxUser}>
              <IconButton
                onClick={handleClickFriendModal}
                className="allIconInput"
              >
                <AiOutlineUsergroupAdd
                  style={{ fontSize: "18px", color: "#000" }}
                />{" "}
                <span
                  style={{ fontSize: "12px", fontWeight: "700", color: "#000" }}
                >
                  {localeFunction("feed_span_text")}
                </span>
                <IoMdArrowDropdown
                  style={{ fontSize: "18px", color: "#000" }}
                />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Box width={"100%"}>
            <FormControl fullWidth className={classes.commentControl}>
              <TextareaAutosize
                className={classes.TextFiles}
                aria-label="minimum height"
                value={inputStr}
                minRows={3}
                maxLength={1000}
                onChange={(e) => setInputStr(e.target.value)}
                placeholder={localeFunction("feed_placeholder_text")}
              />
            </FormControl>
          </Box>
          <Box style={{ marginTop: "-18px" }}>
            <IconButton className="allIconInput">
              <ModalEmoji onEmojiClick={onEmojiClick} />
            </IconButton>
          </Box>
        </Box>

        {addFileOpen ? (
          <>
            <Box
              className={classes.mainmodalBox}
              style={{ maxHeight: "40vh", overflowY: "scroll" }}
            >
              <Box className={classes.dropzoneBox}>
                <IconButton
                  className={classes.photoCancelBtn}
                  onClick={DeleteAllFiles}
                >
                  <IoClose color="#000" />
                </IconButton>
                <label
                  className={classes.lableText}
                  style={{
                    flexDirection:
                      (selectedAll.length > 0 && selectedImages.length > 0) ||
                      selectedVideos.length > 0
                        ? "row"
                        : "column",
                  }}
                >
                  <span className={classes.addPhotoIcon}>
                    <AddPhotoAlternateIcon />
                  </span>
                  {localeFunction("feed_story_text")}
                  <input
                    type="file"
                    name="images"
                    onChange={onSelectFile}
                    accept="image/png , image/jpeg, image/webp,video/*"
                    className={classes.inputFile}
                    // multiple
                  />
                </label>
                <br />

                <Box className={classes.images}>
                  <FbImageGrid
                    onClickEach={({ src, index }) => {
                      deleteHandlerImage(src);
                    }}
                    width={100}
                    images={selectedImages ? selectedImages : ""}
                    // hideOverlay={true}
                    overlayBackgroundColor="#6fcfb9"
                    renderOverlay={() => (
                      <Button className={classes.deleteBtn}>
                        <DeleteIcon />
                      </Button>
                    )}
                  />
                  <Grid container justifyContent="center">
                    {selectedVideos &&
                      selectedVideos.map((image, index) => {
                        return (
                          <>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                              <Box
                                className={classes.image}
                                style={{ marginTop: "4rem" }}
                              >
                                <video
                                  controls
                                  style={{
                                    width: "100%",
                                    // maxHeight: "50vh",
                                    height: "40vh",
                                  }}
                                >
                                  <source
                                    type="video/mp4"
                                    src={image.blobData}
                                  />
                                </video>
                                <Button
                                  onClick={() =>
                                    deleteHandlerVideo(
                                      image.blobData,
                                      image.fileName
                                    )
                                  }
                                  className={classes.deleteBtn}
                                >
                                  <DeleteIcon />
                                </Button>
                              </Box>
                            </Grid>
                          </>
                        );
                      })}
                  </Grid>
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          ""
        )}

        <Box className={classes.mainmodalBox}>
          <Box mt={3} className={classes.PostBox}>
            <Box>
              <IconButton
                className="allIconInput"
                style={{ cursor: "default" }}
              >
                <Typography variant="h4">
                  {localeFunction("feed_add_post_text")}
                </Typography>
              </IconButton>
            </Box>
            <Box display="flex">
              <IconButton
                // onClick={() => setShowDropZone(false)}
                className="allIconInput"
                onClick={() => {
                  toggleFileBtn();
                }}
                style={{
                  background: addFileOpen ? "#e4e6eb" : "none",
                }}
              >
                <PhotoLibraryIcon className={classes.iconBtns} />
              </IconButton>
              <IconButton
                onClick={handleClickOpenModal}
                className="allIconInput"
              >
                <MdAddLocationAlt className={classes.iconBtns} />
              </IconButton>
              <IconButton
                onClick={handleClickFriendModal}
                className="allIconInput"
              >
                <HiUserAdd className={classes.iconBtns} />
              </IconButton>
            </Box>
          </Box>
          {allUploaded ? (
            <>
              {isUploading ? (
                <Box style={{ marginTop: "4px" }}>
                  <ButtonCircularProgress />
                </Box>
              ) : (
                <Grid container spacing={2} style={{ marginTop: "4px" }}>
                  {allImages && (
                    <>
                      {allImages &&
                        allImages.map((image, index) => {
                          return (
                            <Grid item xs={2}>
                              <Box display="flex" flexWrap="wrap">
                                <Box mr={1} className={classes.minbox}>
                                  <img
                                    src={image["data_url"]}
                                    alt=""
                                    style={{ width: "100%", height: "70px" }}
                                  />
                                </Box>
                                <Button
                                  disabled={isLoading}
                                  style={{ marginLeft: "-10px" }}
                                  onClick={() =>
                                    handleRemove(image["data_url"])
                                  }
                                >
                                  {localeFunction("feed_remove_text")}
                                </Button>
                              </Box>
                            </Grid>
                          );
                        })}
                    </>
                  )}
                </Grid>
              )}
            </>
          ) : (
            ""
          )}

          {successFile !== "" && (
            <Typography variant="body2" style={{ marginTop: "10px" }}>
              {successFile}
            </Typography>
          )}
          {finaltaguserdata?.length > 0 && (
            <Box
              mt={2}
              className="flexbox"
              display={"flex"}
              alignItems="center"
            >
              <HiUserAdd />
              &nbsp;
              {finaltaguserdata?.length === 1 ? (
                <Typography variant="body2">
                  {finaltaguserdata[0]?.name}&nbsp;
                  {localeFunction("feed_tag_post_text")}
                </Typography>
              ) : finaltaguserdata?.length === 2 ? (
                <Typography variant="body2">
                  {finaltaguserdata[0]?.name}&nbsp;
                  {localeFunction("feed_and_text")}&nbsp;
                  {finaltaguserdata[1]?.name}
                  &nbsp;{localeFunction("feed_tag_post_text")}
                </Typography>
              ) : finaltaguserdata?.length === 3 ? (
                <Typography variant="body2">
                  {finaltaguserdata[0]?.name},&nbsp;
                  {finaltaguserdata[1]?.name}&nbsp;
                  {localeFunction("feed_and_text")}&nbsp;
                  {finaltaguserdata[2]?.name}
                  &nbsp;{localeFunction("feed_tag_post_text")}
                </Typography>
              ) : (
                <>
                  <Typography variant="body2">
                    {finaltaguserdata[0]?.name},&nbsp;{finaltaguserdata[1]?.name}&nbsp;{localeFunction("feed_and_text")}&nbsp;{finaltaguserdata?.length - 2}&nbsp;{localeFunction("feed_tag_post_text1")}
                  </Typography>
                </>
              )}
            </Box>
          )}

          <Box className={classes.newLocationAddress}>
            <Box className="flexbox">
              <Box className="flexbox" mt={1}>
                {window.localStorage.getItem("postLocation") ? (
                  <>
                    <LocationOnIcon
                      style={{ fontSize: "14px", color: "green" }}
                    />
                    <Typography
                      variant="body2"
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {window.localStorage.getItem("postLocation")}
                    </Typography>
                  </>
                ) : (
                  ""
                )}
              </Box>
              <>&nbsp;</>
            </Box>
          </Box>

          {allUploaded ? (
            <Box mt={3} align="center">
              <Button
                variant="contained"
                color="secondary"
                disabled={
                  isLoading ||
                  isUploading1 ||
                  (inputStr === "" && selectedAll?.length === 0)
                }
                style={{ padding: "10px 60px" }}
                onClick={handleFormSubmit}
              >
                {localeFunction("post_story_text")}
                {isLoading && <ButtonCircularProgress />}
              </Button>
            </Box>
          ) : (
            ""
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default CreatePostModal;
